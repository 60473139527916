body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: Arial;
  
 }

@font-face {
  font-family: cursiveFont;
  src: url("../src/assets/fonts/heartbroke-font/Heartbroke-WylGE.ttf")
}

@font-face {
  font-family: textFont;
  src: url("../src/assets/fonts/didot-font/Didot-w5Rx.otf")
}




/* NAVBAR ------------------------------------------------------------------- */

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 2em;
  }
  
  @media (max-width: 768px) {
  .hamburger {
  display: block;
  color: white;
  background-color: transparent;
  border: aliceblue;
  }
  
  .navbar-collapse {
  height: 0;
  width: 0;
  background: #f2f2f2;
  position: absolute;
  top: 10vh;
  right: 0;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  
  .navLink {
  color: white;
  font-size: 30px;
  margin: 1em 0;
  text-align: center;
  padding-right: 0 !important;
  }
  
  .show {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: absolute;
  top: 0;
  background: rgb(195 166 160);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

  .navIcon {
    margin: 0 !important;
    left: 0 !important;
    margin-left: -22% !important;
    padding-bottom: 3%;
  }

  
  }






/* -----------------------------------------------------------------------------NAVBAR */



#ContactPage img {
  margin-top: 25%;
  height: 80%;
}


/* about ------- */






@media all and (min-width: 2000px) and (max-width: 2500px) { 

  .heroBigText {
    padding-top: 40px !important;
    margin-right: 50% !important;
    width: 140% !important; 
    font-size: 10rem !important;
  }
  .heroSmallText {
    padding-top: -5% !important;
width: 100% !important;
  }

  .contactLeft {
    position: relative;
    bottom: 200px;
  }
}

@media all and (min-width: 1800px) and (max-width: 2000px) { 

  .heroBigText {
    padding-top: 100px !important;
    width: 150% !important; 
    font-size: 10rem !important;
  }
  .heroSmallText {
    padding-top: -5% !important;
width: 100% !important;
  }

  .contactLeft {
    position: relative;
    bottom: 200px;
  }
}



  
@media all and (min-width: 1500px) and (max-width: 1800px) { 

  .aboutButton {
    width: 21.5% !important;
  }

  .contactButton {
    width: 21.5% !important;
  }

  .columnSection {
    transform: scale(.9) !important;
  }

  .serviceListItemsMain {
    width: 165% !important;
  }

  .heroBigText {
    padding-top: 170px !important;
    width: 150% !important; 
    font-size: 9rem !important;
  }
  .heroSmallText {

  width: 90% !important;
  font-size: 1.2rem !important;
  }

  .contactLeft {
    position: relative;
    bottom: 200px;
  }

}








@media all and (min-width: 1280px) and (max-width: 1500px) { 

  .columnSection {
    transform: scale(.9) !important;
  }

  .serviceListItemsMain {
    width: 165% !important;
  }

  .heroBigText {
    padding-top: 300px !important;
    width: 150% !important
  }

  .contactLeft {
    position: relative;
    top: -300px !important;
  } 

  #Contact {
    padding-top: 15%;
  }

  .contactButton {
    width: 25.5% !important;
}

.aboutButton {
  width: 26.5% !important;
}

.servicePageListContainer {
  width: 140% !important;
}

}


  
/* @media all and (min-width: 1000px) and (max-width: 1280px) {


  .heroBigText {
    padding-top: 350px !important;
    padding-right: 400px;
   justify-content: center;
    width: 150% !important
  }

  .serviceListItemsMain {
    width: 185% !important;
  }

  li {
    font-size: .7rem !important;
  }
 #About p {
  font-size: .8rem !important;
 }

.cursiveH2 {
  font-size: 4rem !important;
}

.headerBigLetter {
  font-size: 6rem !important;
 }

 .aboutButton {
  width: 35% !important;
 }



.contactLeft {
  position: relative;
  top: -300px !important;
} 

#Contact {
  padding-top: 15%;
}

.contactButton {
  width: 28.5% !important;
}

.aboutButton {
width: 29.5% !important;
}

.columnSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.serviceListItemsMain {
  width: 100% !important;
}



 } */







@media all and (min-width: 1000px) and (max-width: 1280px) {
  .heroLayout {
    position: relative;
  }
  
  .heroBigText {
    font-size: 7rem !important;
    display: inline-block;
    padding-bottom: 0 !important;
    color: black !important;
    padding-top: 90%;
    font-weight: 600 !important;
    line-height: 5rem !important;
  }
  
  
  
  .heroSmallText {
  font-size: 1rem !important;
  color: rgb(195 166 160) !important;
  font-weight: 600;
  width: 160% !important;
  line-height: 1rem !important;
  position: relative;
  bottom: -50px !important;
  left: -75px;
  }

  .columnSection {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .serviceListItemsMain {
    width: 100% !important;
  }

  li {
    font-size: .7rem !important;
    width: 100% !important;
  }
  p{
    font-size: .9rem !important;
  }

  #Service li {
    font-size: .55rem !important;
    width: 100% !important;
}

.columnSection {
  width: 100% !important;
}

.contactLeft{
position: relative;
top: -250px;
}

.contactButton {
  width: 23% !important;
}

.servicePageMobile {
  margin-left: 100px !important;
}



 }

 @media all and (min-width: 768px) and (max-width: 1000px) {
  .heroLayout {
    position: relative;
  }
  
  .heroBigText {
    font-size: 7rem !important;
    display: inline-block;
    padding-bottom: 0 !important;
    color: black !important;
    padding-top: 90%;
    font-weight: 600 !important;
    line-height: 5rem !important;
  }
  
  
  
  .heroSmallText {
  font-size: 1rem !important;
  color: rgb(195 166 160) !important;
  font-weight: 600;
  width: 160% !important;
  line-height: 1rem !important;
  position: relative;
  bottom: -50px !important;
  left: -75px;
  }

  .columnSection {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .serviceListItemsMain {
    width: 100% !important;
  }

  li {
    font-size: .9rem !important;
    width: 100% !important;
  }
  p{
    font-size: .9rem !important;
  }

  #Service li {
    font-size: .6rem !important;
    width: 100% !important;
}

.columnSection {
  width: 100% !important;
}

.contactLeft{
position: relative;
top: -250px;
}

.contactButton {
  width: 23% !important;
}
.aboutButton {
  width: 27% !important;
}

.servicePageListContainer {
  width: 140% !important;
}

#ServicePage li {
  font-size: .7rem !important;
}

.servicePageMobile {
  margin-left: 100px !important;
}


 }


 @media (max-width: 768px) {
  div {
    flex-direction: column;
  }
  h2 {
    font-size: 4rem;
  }
  p {
    font-size: 1.5rem !important;
    width: 125% !important;
    align-items: left !important;
  }
  img {
    width: 85%;
    height: auto;
    margin-top: 40%;
    margin-bottom: 1%;
    box-shadow: rgb(195 166 160) 60px -50px 0px 0px;
}
ul {
  flex: 1;
  padding: 0 !important;
  text-align: left;
}

li {
  font-size: .9rem;
  font-family: "regularFont";
  margin-top: 0;
  width: 100%;
  line-height: 1.6rem;
  letter-spacing: .05em;
  color: "#706d6d";
}


.headerRow {
    flex-direction: row;
  }

  .cursiveH2 {
    font-size: 4rem !important;
  }

  .headerBigLetter {
    font-size: 6rem !important;
  }

  .columnSection {
    width: 90% !important;
    padding: 0 !important;
    align-items: center;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center !important;
    width: 34% !important;
  }
  

/* /////////////////////hero//////////////// */

.heroImage {
  position: relative !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  width: 100vw;
  height: auto;
  top: 60px !important;
}

.heroLayout {
  position: relative;
}

.heroBigText {
  display: inline-block;
  padding-top: 75%;
  padding: 0 !important;
 font-size: 75px !important;
 width: 100%;
 margin: 0 !important;
 padding-bottom: 40px !important;
 color: rgb(0, 0, 0) !important;
 font-weight: 600 !important;
 line-height: 4rem !important;
}



.heroSmallText {
font-size: .8rem !important;
color: rgb(195 166 160) !important;
font-weight: 600;
width: 160% !important;
line-height: 1rem !important;
position: relative;
top: 50px !important;
left: -75px;
  

}



.heroNoPadding {
  padding-bottom: 0px !important;
}


  /* ///////service //////////// */
  #Service {
    flex-direction: column-reverse;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important
  }

  #About{
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important
  }

  #About img {
    width: 80% !important;
  }

  #Contact{
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important
  }







/* -------------------------Service Section ---------------------------------------- */
  #Service p {
    width: 150% !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-right: 40% !important;
  }

  #Service .serviceListItemsMain {
    flex-direction: row;
    width: 150% !important;
    overflow:  hidden !important;
    justify-content: space-between !important;
    margin-right: 10% !important;
    padding-left: 10% !important;
  }

  #Service li {
    font-size: .55rem !important;
    width: 100% !important;
   
  }

#Service img {
  margin-left: 35%;
}

#Service button {
    display: flex;
    margin: 0 !important;
    width: 55% !important;
    margin-left: 0% !important;

}

/* ---------------------------Contact Section-------------------------------------- */
#Contact{
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  padding-top: 0 !important
}

#Contact h2{
  margin: 0 !important;
  padding-bottom: 5%;
}


#Contact p {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 5% !important;
  padding-bottom: 5%;
}

#Contact button {
margin-top: 5%;
}

.contactNumber {
  font-size: 1rem !important;
}

.contactEmail {
  font-size: 1rem !important;
}









/* -------------------------Service Page--------------------------------------- */

#ServicePage div {
  height: auto !important;
  margin: 0 !important;
  padding: 0!important;
  padding-top: 5% !important;
}

.servicePageListContainer {
  display: flex !important;
  flex-direction: initial;
  justify-content: center !important;
  align-items: center;
}

#ServicePage p {
  width: 100% !important;
}

#ServicePage li {
  font-size: .7rem !important;
  margin: 0px 20px;
  width: 100% !important;
}

#ServicePage img {
  margin-left: 50px;
  padding-bottom: 10px;
  margin-top: 70px !important;
}








/* --------------------------About Page--------------------------------------- */


#AboutPage{
  margin: 0 !important;
  padding: 0 !important;
  width: 80% !important;
  padding: 5% !important;
  width: 85% !important;
}

#AboutPage img {
  width: 80% !important;
  padding: 0 !important;
}

#AboutPage button {
  width: 35% !important;
}


.aboutPageSet {
  padding: 0 !important;
}



/* ---------------------------------ContactPage-------------------------------------------- */

#ContactPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: auto;
  width: auto;
  overflow: hidden !important;
}

.contactContainer {
  display: block !important;
}


.contactColumnReverse {
  flex-direction: column-reverse !important;
  align-items: center;
  justify-content: center;
}

form {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

label, input, button {
  font-size: 0.9rem;
}

.contactForm {
  width: 80% !important;
  height: 80%;
  box-shadow: none !important;
  margin: 0%;

}

.contactPageBottomSection {
  width: 80% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contactPageTopSection {
  width: 80% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.eventDateInput {
  height: 42px;
  margin-top: 1px !important;
}

.contactFormButton {
  background-color:#f2f2f2;
  display: block;
  margin: 0 auto;
}


/* ---------------------------------Gallery-------------------------------------------- */

#Gallery {
  padding: 0 !important;
}

.galleryPadding{
  padding: 0px !important;
}

.galleryPaddingHeader{
  padding-top: 30% !important;
}

#Gallery img {
  padding: 50% !important;
  margin-top: 5px !important;
}


.galleryClickThroughImages {
  max-height: 80vh !important;
  max-width: 80vw !important;
  box-shadow: none;
  padding-bottom: 25%;
}

.prevButton,
.closeButton,
.nextButton {
  position: absolute;
  bottom: 0px;
  margin-top: 5%;
}
.prevButton {
  left: 20px;
}
.nextButton {
  right: 20px;
}

.closeButton {
  left: 50%;
  transform: translateX(-50%);
  justify-content: center !important;
  align-items: center !important;
  width: 20% !important;
  background: black;
  color: white;
  border-radius: 10%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

}








  


